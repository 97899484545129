.console {
  height: 100%;
  position: relative;
  &-header{
    padding: 10px;
    background: #4e4e4e;;
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    & .title{
      color:white;
    }
    & .right{
      column-gap: 10px;
      display: flex;
    }
  }
  a {
    color:white;
  }
  &-list{
    height: calc(100% - 63px);
    overflow-y: auto;
    background-color: #263238;

    & div[data-method] {
      margin-left: 0 !important;
      padding-left: 0px !important;
      & :nth-child(2){
        margin-left: 4px !important;
        line-height: 18px;
        font-weight: 500;
      }
    }
  }
  input.ant-input.ant-input-sm.ant-input-borderless {
    color: white;
  }

  &-input{
    position: relative;
    left: 0;
    right: 0;
    min-height: 10px !important;
    background-color: #263238;
    height: 32px !important;
    line-height: 23px;
    color:white !important;
    border-bottom: 1px solid #504242 !important;
    border-radius: unset;
    &.ant-input{
      color:white !important;
      background-color: #263238;
      border-bottom: 1px solid #504242 !important;
    }
    &.ant-input-affix-wrapper-borderless, .ant-input-affix-wrapper-borderless:hover, .ant-input-affix-wrapper-borderless:focus, .ant-input-affix-wrapper-borderless-focused{
      border-bottom: 1px solid #504242 !important;
      background-color: #263238;
    }
  }
}