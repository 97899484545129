body{
  overflow: auto;
}
.main-page-wrapper {
	background: #272b30;
	.container-common {
		max-width: 860px;
		margin: 0 auto;
		.footer-wrapper {
      padding: 30px 0;
      display: flex;
      justify-content: center;
      a{
        width: 38px;
        height: 38px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #272b30;
        margin-right: 15px;        
        box-shadow: 0 0 6px #ccc;
        transition: all 0.3s ease-in-out;
        &:hover{
          box-shadow: none;
        background: white;
        }
        svg{
          font-size: 18px;
        }
      }
      .anticon{
        cursor: pointer;
      }
		}
	}
}