.editor-container{
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  display: flex;
  height: 100%;
  &.bottom{
    padding-top: .1rem;
  }
  &.collapsed{
    flex-grow: 0;
    & .CodeMirror-scroll{
      position: absolute;
    }
  }
}
.editor-title{
  display: flex;
  justify-content: space-between;
  background: #4e4e4e;
  color:white;
  padding: .3rem .3rem .3rem .8rem;
}
.code-mirror-container{
  flex-grow: 1;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
  overflow: hidden;
}
.CodeMirror-wrap pre {
  word-break: break-word;
}
.CodeMirror {
  height: 100%;
}
.expand-compress-icon{
  margin-left: .4rem;
  cursor: pointer;
}